import { defineMessages } from 'react-intl';

export default defineMessages({
  configureMy2NaccessModal3Text1Bluetooth: {
    defaultMessage: 'There are already some credentials created. ',
    id: 'configure.My2NAccess.Modal3.text1.bluetooth',
  },
  configureMy2NaccessModal3Text1MaybeBluetooth: {
    defaultMessage: "There's probably something already set up, but we don't know what.",
    id: 'configure.My2NAccess.Modal3.text1.maybeBluetooth',
  },
  configureMy2NaccessModal3Text2ManyIds: {
    defaultMessage:
      'We found many location IDs. If you want to use online pairing, we need to regenerate all location IDs.',
    id: 'configure.My2NAccess.Modal3.text2.manyIDs',
  },
  configureMy2NaccessModal3Text2MoreIds: {
    defaultMessage: 'We found more location IDs.',
    id: 'configure.My2NAccess.Modal3.text2.moreIDs',
  },
  configureMy2NaccessModal3Text3: {
    defaultMessage: 'Be careful, as the following consequences may occur:',
    id: 'configure.My2NAccess.Modal3.text3',
  },
  configureMy2NaccessModal3Text3LinkText: {
    defaultMessage: 'Learn more about location ID',
    id: 'configure.My2NAccess.Modal3.text3.linkText',
  },
  configureMy2NaccessModal3Text3LinkUrl: {
    defaultMessage:
      'https://wiki.2n.com/hip/conf/latest/en/5-konfigurace-interkomu/5-4-sluzby/5-4-1-rizeni-pristupu#listofparameters',
    id: 'configure.My2NAccess.Modal3.text3.linkUrl',
  },
});
