import { FormControl } from '@mui/material';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import http from '../../../../api';
import { DeviceModelName } from '../../../../components';
import OutlinedTextField from '../../../../components/HookFormFields/TextField';
import { DEVICE_TYPE } from '../../../../config/devices';
import { getNameFromDeviceType, getTypeFromDeviceType, isIntercomOrAccessUnit } from '../../../../helpers/devices';
import { TId } from '../../../../modules/commonTypes';
import { selectors as sitesSelectors } from '../../../../modules/sites';
import { IFormData } from '../validationSchema';
import messages from './messages';
import SerialNumberField from './SerialNumberField';

export interface ISerialNumberControl {
  companyId: TId;
}

export function SerialNumberControl({ companyId }: ISerialNumberControl) {
  const site = useSelector(sitesSelectors.getSiteData);
  const { formState, getFieldState, setValue, watch } = useFormContext<IFormData>();
  const serialNumber = watch('serialNumber');
  const deviceType = watch('deviceType');
  const isIDT = watch('isIDT');
  const isSiteMdu = watch('isSiteMDU');
  const serialNumberState = getFieldState('serialNumber');

  useEffect(() => {
    const isDeviceIntercomOrAccessUnit = isIntercomOrAccessUnit(getTypeFromDeviceType(deviceType));

    setValue('isDeviceIntercomOrAccessUnit', isDeviceIntercomOrAccessUnit);
    setValue('isApartmentRequired', !!isSiteMdu && !isDeviceIntercomOrAccessUnit);
    setValue('isIDT', getTypeFromDeviceType(deviceType) === DEVICE_TYPE.IDT);
  }, [deviceType]);

  useEffect(() => {
    const setDeviceType = (value: string) => {
      setValue('deviceType', value);

      if (value) {
        if (!formState.touchedFields.name) {
          setValue('name', getNameFromDeviceType(value), { shouldValidate: true });
        }
      }
    };

    if (serialNumber && new RegExp(/^(\d{2}-\d{4}-\d{4})$/).test(serialNumber)) {
      http
        .get(`/companies/${companyId}/sites/${site.id}/device-meta/${serialNumber}`)
        .then((response) => {
          const { data, status } = response;
          if (status === 409) {
            setDeviceType('');
          } else if (data.deviceType !== null && data.deviceType.length > 0) {
            setDeviceType(data.deviceType);
          }
        })
        .catch(() => {
          setDeviceType('');
        });
    } else {
      setDeviceType('');
    }
  }, [serialNumber]);

  return (
    <FormControl fullWidth>
      <OutlinedTextField
        autoFocus
        error={!serialNumberState.error}
        InputProps={{ inputComponent: SerialNumberField }}
        label={<FormattedMessage {...messages.serialNumberControlInputLabel} />}
        name="serialNumber"
        required
        fullWidth
        helperText={
          !serialNumberState.error && (
            <DeviceModelName deviceType={deviceType} unknownType={false} type={isIDT ? DEVICE_TYPE.IDT : ''} />
          )
        }
      />
      {/* <FormHelperText error={!serialNumberState.error}>{serialNumberState.error?.message ?? ''}</FormHelperText> */}
    </FormControl>
  );
}
